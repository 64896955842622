export const login = async (username: string, password: string, token: string) => {
  const response = await fetch(`/api/auth/login`, {
    method: "POST",
    body: JSON.stringify({ username, password, token }),
  });
  if (!response.ok) {
    const error = ((await response.json()) as { error: string }).error;
    throw new Error(error);
  }
};
