import { z } from "zod";

export const emailSchema = z
  .string({
    invalid_type_error: "Email address needs to be a string",
    required_error: "Email address is required",
  })
  .email({ message: "Email address is invalid" })
  .max(320, {
    message: "Email address needs to be at most 320 characters long",
  })
  .describe("Email");

export const passwordSchema = z
  .string({
    invalid_type_error: "Password needs to be a string",
    required_error: "Password is required",
  })
  .min(8, { message: "Password needs to be at least 8 characters long" })
  .describe("Password")
  .superRefine((password, ctx) => {
    // Check if password has at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password needs to have at least one uppercase letter",
      });
    }
    // Check if password has at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password needs to have at least one lowercase letter",
      });
    }
    // Check if password has at least one number
    if (!/[0-9]/.test(password)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password needs to have at least one number",
      });
    }
    // Check if password has at least one special character
    if (!/[!~`@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password needs to have at least one special character",
      });
    }
  });

export const fullNameSchema = z
  .string({
    invalid_type_error: "Full name needs to be a string",
    required_error: "Full name is required",
  })
  .min(2, { message: "Full name needs to be at least 2 characters long" })
  .max(255, { message: "Full name needs to be at most 255 characters long" })
  .describe("Full name");

export const verificationCodeSchema = z
  .string({
    invalid_type_error: "Code needs to be a string",
    required_error: "Code is required",
  })
  .min(6, { message: "Code needs to be 6 characters long" })
  .max(6, { message: "Code needs to be 6 characters long" })
  .describe("Code");

export const usernameSchema = z
  .string({
    invalid_type_error: "Username needs to be a string",
    required_error: "Username is required",
  })
  .min(2, { message: "Username needs to be at least 2 characters long" })
  .max(255, { message: "Username needs to be at most 255 characters long" })
  .describe("Username");
