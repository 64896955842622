import * as z from "zod";
import {
  passwordSchema,
  verificationCodeSchema,
  emailSchema,
} from "@cloudifybiz/lighthouse-core/zod/schema/auth";
import { api } from "@/trpc/client";

export const verificationFormSchema = z.object({
  code: verificationCodeSchema,
});

export type VerificationFormSchema = z.infer<typeof verificationFormSchema>;

export const signInFormSchema = z.object({
  username: emailSchema,
  password: z.string({
    invalid_type_error: "Password needs to be a string",
    required_error: "Password is required",
  }),
  token: z.string({
    invalid_type_error: "Captcha verification token needs to be a string",
    required_error: "Captcha verification token is required",
  })
});

export type SignInFormSchema = z.infer<typeof signInFormSchema>;

export const signUpFormSchema = z
  .object({
    name: z
      .string({
        invalid_type_error: "Name needs to be a string",
        required_error: "Name is required",
      })
      .max(255, "Name is too long"),
    email: emailSchema.refine(
      async (value) =>
        (await api.auth.emailAvailable.query({ email: value })).available,
      "Email already exists",
    ),
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export type SignUpFormSchema = z.infer<typeof signUpFormSchema>;

export const forgotPasswordSchema = z.object({
  email: emailSchema,
});

export type ForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;

export const resetPasswordSchema = z
  .object({
    code: verificationCodeSchema,
    newPassword: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;
