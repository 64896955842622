"use client";

import { useCallback } from "react";
import { useRouter } from "next/navigation";

import { SignInForm } from "@/components/form/sign-in";
import { type SearchParams } from "@/types";
import { getParam } from "@/utils/misc";

export const Login = ({ searchParams }: { searchParams?: SearchParams }) => {
  const router = useRouter();

  const onSignInSuccess = useCallback(() => {
    const next = searchParams && getParam(searchParams, "next");
    if (next) {
      router.push(next);
      return;
    } else router.push("/");
  }, [router, searchParams]);

  return <SignInForm onSignInSuccess={onSignInSuccess} />;
};
