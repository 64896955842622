"use client";

import { getUrl, transformer } from "./shared";
import { httpBatchLink, loggerLink } from "@trpc/client";
import {
  experimental_createActionHook,
  experimental_createTRPCNextAppDirClient,
  experimental_serverActionLink,
} from "@trpc/next/app-dir/client";
import { type AppRouter } from "@cloudifybiz/lighthouse-core/trpc/root";

export const api = experimental_createTRPCNextAppDirClient<AppRouter>({
  config() {
    return {
      transformer,
      links: [
        loggerLink({
          enabled: (op) =>
            process.env.NODE_ENV === "development" ||
            (op.direction === "down" && op.result instanceof Error),
        }),
        httpBatchLink({
          url: getUrl(),
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
          headers() {
            return {
              "x-trpc-source": "client",
              ...(process.env.NODE_ENV !== "production" && {
                "x-local-cookie": document.cookie,
              }),
            };
          },
        }),
      ],
    };
  },
});

export const useAction = experimental_createActionHook({
  links: [loggerLink(), experimental_serverActionLink()],
  transformer,
});

/** Export type helpers */
export type * from "./shared";
